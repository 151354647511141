<template>
    <mercur-card v-if="isAllowedTo('SupplierCentral/getArtworkManagement')" class="fill full-height-layout mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Artwork Management
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
        ></data-table>
    </mercur-card>
    <p v-else class="permission-message">Not allowed to see this view</p>
</template>

<script>
import DataTable from '@/components/DataTable'
import GridHeader from '@/components/GridHeader'
import CONFIG from '@root/config'

export default {
    name: 'SupplierOverview',
    components: { DataTable, GridHeader },
    data () {
        return {
            options: {
                columns: {
                    'Product Template': {
                        field: 'productConfigurationName',
                        sortable: true,
                    },
                    'Total Unique Combinations': {
                        field: 'uniqueCombinationsAmount',
                        statusChip: true,
                        valueFormatter: ({ value }) => {
                            return value || 'NEW'
                        },
                        sortable: true,
                    },
                    'Basic config missing': {
                        field: 'missingBasicArtworkConfigurationAmount',
                        cellRenderer: ({ value }) => {
                            return `<span>${value || '-'}</span>`
                        },
                        cellClass: params => {
                            return params.value && 'pretty-chip'
                        },
                        sortable: true,
                    },
                    'Manual check config missing': {
                        field: 'missingManualArtworkConfigurationAmount',
                        cellRenderer: ({ value }) => {
                            return `<span>${value || '-'}</span>`
                        },
                        cellClass: params => {
                            return params.value && 'pretty-chip'
                        },
                        sortable: true,
                    },
                    'Autocheck config missing': {
                        field: 'missingAutoArtworkConfigurationAmount',
                        cellRenderer: ({ value }) => {
                            return `<span>${value || '-'}</span>`
                        },
                        cellClass: params => {
                            return params.value && 'pretty-chip'
                        },
                        sortable: true,
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                    },
                    'Artwork Agent': {
                        field: 'accountName',
                        sortable: true,
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-edit',
                        text: 'Products',
                        tooltipText: 'Edit',
                        to: params => ({
                            name: 'ArtworkManagementStartStep',
                            params: {
                                productConfigurationId: params.data.productConfigurationId,
                            },
                        }),
                    },
                ],

                quickSearchColumns: ['productConfigurationName'],
            },
            url: CONFIG.API.ROUTES.ARTWORK.CONFIGURATION.OVERVIEW,
            filters: {
                search: '',
            },
        }
    },
}
</script>

<style lang="scss" scoped>
    .order-identifier-search {
        width: 100vw;
        max-width: 800px;
    }
</style>

<style lang="scss">
    .pretty-chip span {
        color: #6674d8;
        font-weight: bold;
        background-color: #d2e1ff;
        padding: 4px 5px;
        border-radius: 14px;
    }
</style>
